<template>
  <LayoutBlankMaster>
    <slot />
  </LayoutBlankMaster>
</template>

<script>
import LayoutBlankMaster from '@/@core/layouts/variants/blank/LayoutBlank.vue';

export default
{
  name: 'LayoutBlank',
  components:
  {
    LayoutBlankMaster,
  },
};
</script>
