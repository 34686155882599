<template>
  <div class="d-flex flex-grow-1">
    <v-spacer />
    <v-btn outlined style="opacity: 0.3;" class="app-bar-search-toggler px-2 blue-grey" @click="shallShowFullSearchLocal = true">
      <v-icon class="mr-2">{{ icons.mdiMagnify }}</v-icon>
      {{ $t('search') }}
      <v-icon class="ml-5">{{ icons.mdiAppleKeyboardCommand }}</v-icon>
      <v-icon>{{ icons.mdiAlphaK }}</v-icon>
    </v-btn>
    <v-spacer />

    <!-- This is clever hack to hide scrolling 😉 -->
    <v-dialog v-model="shallShowFullSearchLocal" hide-overlay persistent />
    <v-expand-transition>
      <div v-show="shallShowFullSearchLocal" class="app-bar-global-search">
        <v-text-field
          ref="refAutocomplete"
          v-model.trim="searchQueryLocal"
          class="app-bar-autocomplete-box"
          :background-color="$vuetify.theme.dark ? null : 'white'"
          solo
          hide-details
          :placeholder="$t('search_placeholder')"
          @keyup.esc="shallShowFullSearchLocal = false"
          @keydown="arrowNavigation"
          @blur="maybeClosing"
        >
          <template #prepend-inner>
            <v-progress-circular v-if="searching" size="24" color="grey" indeterminate />
            <v-icon v-else size="22" class="mr-1">{{ icons.mdiMagnify }}</v-icon>
          </template>

          <template #append>
            <v-btn icon :color="$vuetify.theme.dark ? 'white' : null" @click="shallShowFullSearchLocal = false">
              <v-icon>{{ icons.mdiClose }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>

        <v-slide-y-transition>
          <v-card v-if="searchQueryLocal" class="mt-4 rounded-lg">
            <v-card-text ref="results" class="pt-0">
              <div v-if="results.length === 0" class="pt-5">
                {{ searching ? $t('searching') : $t('no_results') }}
              </div>
              <template v-else>
                <!-- Talents -->
                <template v-if="talentResults.length > 0">
                  <div class="text-uppercase pt-5 pb-4" style="opacity: 0.65;">
                    {{ $t('creators') }}
                  </div>
                  <v-btn
                    v-for="artist in talentResults"
                    :key="artist.id"
                    block
                    :color="artist === results[focusedIndex] ? ($vuetify.theme.dark ? 'disabled' : 'grey lighten-1') : ''"
                    :text="artist !== results[focusedIndex]"
                    depressed
                    min-height="50"
                    class="mb-2 px-2"
                    @click="gotoTalent(artist.id)"
                  >
                    <div class="pr-3">
                      <v-avatar :size="48" :color="artist.preview ? 'transparent' : 'primary'" class="v-avatar-light-bg primary--text">
                        <img v-if="artist.preview" :src="artist.preview">
                        <v-icon v-else color="primary" size="28">{{ icons.mdiAccountOutline }}</v-icon>
                      </v-avatar>
                    </div>
                    <div class="text-left">
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <div class="invoice_name" v-html="artist.title" />
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <div v-html="artist.content" />
                    </div>
                    <v-spacer />
                  </v-btn>
                </template>
                <!-- Brands -->
                <template v-if="brandResults.length > 0">
                  <div class="text-uppercase pt-5 pb-4" style="opacity: 0.65;">{{ $t('brands') }}</div>
                  <v-btn
                    v-for="company in brandResults"
                    :key="company.id"
                    block
                    :color="company === results[focusedIndex] ? ($vuetify.theme.dark ? 'disabled' : 'grey lighten-1') : ''"
                    :text="company !== results[focusedIndex]"
                    depressed
                    min-height="50"
                    class="mb-2 px-2"
                    @click="gotoBrand(company.id)"
                  >
                    <div class="pr-3">
                      <v-avatar :size="48" :color="company.preview ? 'transparent' : 'primary'" class="v-avatar-light-bg primary--text">
                        <img v-if="company.preview" :src="company.preview">
                        <v-icon v-else color="primary" size="28">{{ icons.mdiAccountOutline }}</v-icon>
                      </v-avatar>
                    </div>
                    <div class="text-left">
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <div class="invoice_name" v-html="company.title" />
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <div v-html="company.content" />
                    </div>
                    <v-spacer />
                  </v-btn>
                </template>
                <!-- Agencies -->
                <template v-if="agencyResults.length > 0">
                  <div class="text-uppercase pt-5 pb-4" style="opacity: 0.65;">{{ $t('agencies') }}</div>
                  <v-btn
                    v-for="company in agencyResults"
                    :key="company.id"
                    block
                    :color="company === results[focusedIndex] ? ($vuetify.theme.dark ? 'disabled' : 'grey lighten-1') : ''"
                    :text="company !== results[focusedIndex]"
                    depressed
                    min-height="50"
                    class="mb-2 px-2"
                    @click="gotoAgency(company.id)"
                  >
                    <div class="pr-3">
                      <v-avatar :size="48" :color="company.preview ? 'transparent' : 'primary'" class="v-avatar-light-bg primary--text">
                        <img v-if="company.preview" :src="company.preview">
                        <v-icon v-else color="primary" size="28">{{ icons.mdiAccountOutline }}</v-icon>
                      </v-avatar>
                    </div>
                    <div class="text-left">
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <div class="invoice_name" v-html="company.title" />
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <div v-html="company.content" />
                    </div>
                    <v-spacer />
                  </v-btn>
                </template>
                <!-- Invoices -->
                <template v-if="invoiceResults.length > 0">
                  <div class="text-uppercase pt-5 pb-4" style="opacity: 0.65;">{{ $t('invoices') }}</div>
                  <v-btn
                    v-for="doc in invoiceResults"
                    :key="doc.id"
                    block
                    :color="doc === results[focusedIndex] ? ($vuetify.theme.dark ? 'disabled' : 'grey lighten-1') : ''"
                    :text="doc !== results[focusedIndex]"
                    depressed
                    class="mb-2 px-2"
                    @click="gotoInvoice(doc.id, true)"
                  >
                    <div class="pr-3">
                      <pdf-invoice width="20" />
                    </div>
                    <div class="text-left">
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <div class="invoice_name" v-html="doc.title" />
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <div v-html="doc.content" />
                    </div>
                    <v-spacer />
                    <div>
                      {{ doc.createdAt | stampLocale($root.$i18n.locale) }}
                    </div>
                  </v-btn>
                </template>
                <!-- Quotations -->
                <template v-if="quotationResults.length > 0">
                  <div class="text-uppercase pt-5 pb-4" style="opacity: 0.65;">{{ $t('quotations') }}</div>
                  <v-btn
                    v-for="doc in quotationResults"
                    :key="doc.id"
                    block
                    :color="doc === results[focusedIndex] ? ($vuetify.theme.dark ? 'disabled' : 'grey lighten-1') : ''"
                    :text="doc !== results[focusedIndex]"
                    depressed
                    class="mb-2 px-2"
                    @click="gotoInvoice(doc.id, false)"
                  >
                    <div class="pr-3">
                      <pdf-invoice width="20" />
                    </div>
                    <div class="text-left">
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <div class="invoice_name" v-html="doc.title" />
                      <!-- eslint-disable-next-line vue/no-v-html -->
                      <div v-html="doc.content" />
                    </div>
                    <v-spacer />
                    <div>
                      {{ doc.createdAt | stampLocale($root.$i18n.locale) }}
                    </div>
                  </v-btn>
                </template>
              </template>
            </v-card-text>
          </v-card>
        </v-slide-y-transition>
      </div>
    </v-expand-transition>
  </div>
</template>

<script>
import {
  mdiMagnify,
  mdiClose,
  mdiReceiptTextOutline,
  mdiFilePercent,
  mdiHumanGreeting,
  mdiStore,
  mdiTent,
  mdiAccountOutline,
  mdiAppleKeyboardCommand,
  mdiAlphaK,
} from '@mdi/js';
import CancelToken from 'axios/lib/cancel/CancelToken';
import store from '@/store';
import { SEARCH_DOCUMENTS_API_URL } from '@/constants/api_routes';
import events, { SNACKBAR_FAILURE } from '@/events';
import pdfInvoice from '@/assets/images/svg/pdf-invoice.svg';
import { keyCodes } from 'vuetify/lib/util/helpers';

export default {
  name: 'AppBarSearch',
  components:
    {
      pdfInvoice,
    },
  props: {
    shallShowFullSearch: {
      type: Boolean,
      default: false,
    },
    searchQuery: {
      type: [String, null],
      default: '',
    },
  },
  data()
  {
    return {
      closeTimer: null,
      searchTimer: null,
      searching: false,
      axiosToken: null,
      results: [],
      focusedIndex: -1,
    };
  },
  computed:
    {
      shallShowFullSearchLocal:
        {
          get()
          {
            return this.shallShowFullSearch;
          },
          set(val)
          {
            this.$emit('update:shallShowFullSearch', val);
          },
        },
      searchQueryLocal:
        {
          get()
          {
            return this.searchQuery;
          },
          set(val)
          {
            this.$emit('update:searchQuery', val);
          },
        },
      icons()
      {
        return {
          mdiMagnify,
          mdiClose,
          mdiAccountOutline,
          mdiAppleKeyboardCommand,
          mdiAlphaK,
          invoice: mdiReceiptTextOutline,
          quotation: mdiFilePercent,
          talent: mdiHumanGreeting,
          brand: mdiStore,
          agency: mdiTent,
        };
      },
      invoiceResults()
      {
        return this.results.filter(item => item.type === 'invoice');
      },
      quotationResults()
      {
        return this.results.filter(item => item.type === 'quotation');
      },
      talentResults()
      {
        return this.results.filter(item => item.type === 'talent');
      },
      brandResults()
      {
        return this.results.filter(item => item.type === 'brand');
      },
      agencyResults()
      {
        return this.results.filter(item => item.type === 'agency');
      },
    },
  watch:
    {
      searchQueryLocal()
      {
        this.clearSearchTimer();
        this.searchTimer = setTimeout(this.performSearch, 400);
      },
      shallShowFullSearchLocal(value)
      {
        if (!value)
        {
          this.clearCloseTimer();
          this.searchQueryLocal = '';
        }
        store.commit('app/TOGGLE_CONTENT_OVERLAY', value);

        // ? We create our own autofocus logic because `autofocus` prop was creating issue on `esc` key
        if (value)
        {
          this.results = [];
          setTimeout(() =>
          {
            this.$refs.refAutocomplete.focus();
          }, 150);
        }
      },
    },
  mounted()
  {
    window.addEventListener('keydown', this.keydownHandler);
  },
  beforeDestroy()
  {
    this.clearSearchTimer();
    this.clearCloseTimer();
    this.cancelAxios();
    window.removeEventListener('keydown', this.keydownHandler);
  },
  methods:
    {
      keydownHandler(e)
      {
        const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
        if (e.key === 'k' && ((isMac && e.metaKey) || (!isMac && e.ctrlKey)))
        {
          e.preventDefault();
          this.shallShowFullSearchLocal = true;
        }
      },
      maybeClosing()
      {
        this.clearCloseTimer();
        this.closeTimer = setTimeout(this.closeSearch, 200);
      },
      cancelAxios()
      {
        if (this.axiosToken)
        {
          this.axiosToken.cancel();
          this.axiosToken = null;
        }
      },
      clearCloseTimer()
      {
        if (this.closeTimer)
        {
          clearTimeout(this.closeTimer);
        }
      },
      clearSearchTimer()
      {
        if (this.searchTimer)
        {
          clearTimeout(this.searchTimer);
        }
      },
      closeSearch()
      {
        this.shallShowFullSearchLocal = false;
        this.searchQueryLocal = '';
        this.results = [];
      },
      performSearch()
      {
        if (this.searchQueryLocal)
        {
          this.results = [];
          this.cancelAxios();
          this.axiosToken = CancelToken.source();
          this.searching = true;
          this.$http.get(`${SEARCH_DOCUMENTS_API_URL}`, {
            params:
              {
                pagination: false,
                groupByType: true,
                itemsPerPage: 30,
                q: this.searchQueryLocal,
              },
            cancelToken: this.axiosToken.token,
          }).then(response =>
          {
            this.results = response.data['hydra:member'];
            this.focusedIndex = this.results.length > 0 ? 0 : -1;
          })
            .catch(err =>
            {
              events.$emit(SNACKBAR_FAILURE, err);
            })
            .finally(() =>
            {
              this.axiosToken = null;
              this.searching = false;
            });
        }
      },
      gotoInvoice(id, isInvoice)
      {
        this.closeSearch();
        this.$router.push({
          name: isInvoice ? 'invoice-view' : 'quotation-view',
          params:
            {
              id,
            },
        }).catch(() => false);
      },
      gotoTalent(id)
      {
        this.closeSearch();
        this.$router.push({
          name: 'talent-details',
          params:
            {
              id,
            },
        }).catch(() => false);
      },
      gotoBrand(id)
      {
        //
      },
      gotoAgency(id)
      {
        //
      },
      arrowNavigation(e)
      {
        const keyCode = e.keyCode; // eslint-disable-line prefer-destructuring
        if ([keyCodes.up,
          keyCodes.down,
          keyCodes.home,
          keyCodes.end,
          keyCodes.enter].includes(keyCode))
        {
          e.preventDefault();
          this.$nextTick(() =>
          {
            if (keyCode === keyCodes.down)
            {
              if (this.focusedIndex < this.results.length - 1) this.focusedIndex++;
            }
            else if (keyCode === keyCodes.up)
            {
              if (this.focusedIndex > 0) this.focusedIndex--;
            }
            else if (keyCode === keyCodes.home)
            {
              this.focusedIndex = 0;
            }
            else if (keyCode === keyCodes.end)
            {
              this.focusedIndex = this.results.length - 1;
            }
            else
            {
              this.$nextTick(() =>
              {
                const buttons = this.$refs.results.querySelectorAll('.v-btn');
                if (buttons.length > 0 && buttons[this.focusedIndex])
                {
                  buttons[this.focusedIndex].click();
                }
              });
            }
          });
        }
      },
    },
};
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

@include theme(app-bar-autocomplete-box) using($material)
{
  div[role='combobox']
  {
    background-color: map-deep-get($material, 'cards');
  }
}

.v-toolbar__content
{
  padding: 0 0 10px 0;
}

.app-bar-global-search
{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  .invoice-name
  {
    font-size: 1.125rem;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 0.4px;
    opacity: 0.8;
    text-transform: uppercase;
  }

  em
  {
    font-weight: bold;
    background-color: var(--v-success-base);
  }
}

.app-bar-autocomplete-box
{
  border-radius: 0 0 5px 5px !important;

  .v-input__control
  {
    height: 100%;

    .v-input__slot
    {
      padding: 0.7rem 1rem !important;
    }
  }

  div[role='combobox']
  {
    height: 100%;
    z-index: 10;
    padding: 0 20px !important;

    .v-input__prepend-inner,
    .v-input__append-inner
    {
      align-self: center;
      margin-top: -2px;

      svg
      {
        font-size: 24px;
        height: 24px;
        width: 24px;
      }
    }

    .v-input__prepend-inner
    {
      @include ltr()
      {
        margin-left: 4px;
      }
      @include rtl()
      {
        margin-right: 4px;
      }
    }
  }

  // Content - Result
  .v-menu__content
  {
    @include style-scroll-bar();

    max-width: unset;
    width: 100%;
    border-radius: 12px !important;
    top: calc(64px + 0.625rem) !important;

    .v-subheader
    {
      text-transform: uppercase;
      letter-spacing: 0.21px;
      font-size: 0.75rem;
      font-weight: 500;
    }

    @include theme(v-subheader) using($material)
    {
      color: map-deep-get($material, 'text', 'disabled');
    }

    .v-list-item
    {
      > [id^='list-item']
      {
        width: 100%;
      }
    }
  }

  @at-root .v-app-bar.app-bar-shinked &
  {
    div[role='combobox']
    {
      padding: 0 !important;
    }

    // Content - Result
    .v-menu__content
    {
      max-width: unset;
      width: calc(100% + 20px * 2);
      @include ltr()
      {
        margin-left: -20px;
      }
      @include rtl()
      {
        margin-right: -20px;
      }
    }
  }
}

// ————————————————————————————————————
//* ——— Horizontal Nav
// ————————————————————————————————————

.content-layout.horizontal-nav
{
  .app-system-bar
  {
    // Assigning 7 z-index so that search result can be seen on top of navigation menu
    z-index: 7;

    .v-text-field
    {
      margin-top: 0;
      padding-top: 0;
    }

    // Remove 3rem padding from both side.
    // ? 3rem is applied to navbar-content-container
    .app-bar-autocomplete-box
    {
      max-width: calc(100% - 3rem * 2);
      @include ltr()
      {
        margin-left: 3rem;
      }
      @include rtl()
      {
        margin-right: 3rem;
      }
    }

    // ? In Full content contet have padding of 1.5rem
    &:not(.app-system-bar-boxed)
    {
      .app-bar-autocomplete-box
      {
        max-width: calc(100% - 1.5rem * 2);
        @include ltr()
        {
          margin-left: 1.5rem;
        }
        @include rtl()
        {
          margin-right: 1.5rem;
        }
      }
    }
  }
}

.app-bar-search-toggler
{
  text-transform: none !important;
}
</style>

<i18n>
{
  "en": {
    "search": "SEARCH",
    "searching": "Searching ...",
    "no_results": "No results found !",
    "invoices": "Invoices",
    "quotations": "Quotations",
    "creators": "Creators",
    "brands": "Brands",
    "agencies": "Agencies",
    "search_placeholder": "Search for creators, brands, invoices, document number..."
  }
}
</i18n>
