<template>
  <v-card flat>
    <v-card-text class="d-flex">
      <v-avatar rounded size="120" class="me-6 cursor-pointer" :color="photo ? 'transparent' : 'primary'" @click="showUpload">
        <v-img v-if="photo" :src="photo" />
        <v-icon v-else color="white">{{ icons.mdiAccount }}</v-icon>
      </v-avatar>
      <!-- upload photo -->
      <div class="mt-5">
        <v-btn color="primary" class="me-3" @click="showUpload">
          <v-icon class="d-sm-none">{{ icons.mdiCloudUploadOutline }}</v-icon>
          {{ $t('upload_photo') }}
        </v-btn>

        <v-btn color="error" outlined @click="$emit('reset')">{{ $t('reset') }}</v-btn>
        <p class="text-sm mt-5">{{ $t('allowed_files') }}</p>
      </div>
    </v-card-text>

    <v-card-text class="pt-4">
      <v-text-field :value="account.username" :label="$t('email')" outlined readonly />
    </v-card-text>
    <!-- New image -->
    <v-dialog v-model="dlgUpload" persistent width="450">
      <v-form ref="frm" @submit.prevent="showCrop">
        <v-card>
          <v-card-title>{{ $t('new_upload') }}</v-card-title>

          <v-card-text>
            <v-radio-group v-model="typeUpload" hide-details class="mt-0 mb-7">
              <v-radio :value="0" :label="$t('upload_local')" />
              <v-radio :value="1" :label="$t('specify_url')" />
            </v-radio-group>
            <v-file-input
              v-if="typeUpload === 0"
              v-model="newLocalUpload"
              :label="$t('choose_file')"
              outlined
              prepend-icon=""
              :prepend-inner-icon="icons.mdiPaperclip"
              accept=".png,.jpg,.jpeg"
              :loading="uploading"
              :rules="[ruleRequired,validUpload]"
            />
            <v-text-field v-else v-model.trim="newURL" :label="$t('url_image')" :placeholder="imagePlaceholder" outlined :rules="[ruleRequired,validURL]" />
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn color="primary" type="submit" class="mr-2">{{ $t('submit') }}</v-btn>
            <v-btn outlined @click="dlgUpload = false">{{ $t('Cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <!-- Image crop -->
    <DialogMediaCrop v-model="dlgCrop" :media="imageData" @save="changeAvatar" />
  </v-card>
</template>

<script>
import { mdiCloudUploadOutline, mdiAccount, mdiPaperclip } from '@mdi/js';
import { mapGetters, mapMutations } from 'vuex';
import { ruleRequired, validURL } from '@/plugins/validations';
import { MEDIAS_API_URL } from '@/constants/api_routes';
import events, { SNACKBAR_FAILURE } from '@/events';
import DialogMediaCrop from '@/components/DialogMediaCrop.vue';

export default
{
  name: 'AccountSettingsAccount',
  components:
    {
      DialogMediaCrop,
    },
  props:
    {
      account:
        {
          type: Object,
          default: () => ({}),
        },
    },
  data()
  {
    return {
      dlgUpload: false,
      dlgCrop: false,
      imageData: '',
      typeUpload: 0, // 0 = upload local file, 1 = enter public URL
      newLocalUpload: null,
      newURL: '',
      uploading: false,
      photo: null, // URL of the current image
      photoName: '',
      photoBlob: null,
      imagePlaceholder: 'http://www.example.com/picture.png',
    };
  },
  computed:
    {
      ...mapGetters('auth', ['GET_USER_DATA']),
      icons()
      {
        return {
          mdiCloudUploadOutline,
          mdiAccount,
          mdiPaperclip,
        };
      },
    },
  watch:
    {
      account:
        {
          immediate: true,
          handler(newVal)
          {
            this.photoName = '';
            this.clearBlob();
            if (newVal && newVal.mediaAvatar)
            {
              this.photo = newVal.mediaAvatar;
            }
          },
        },
    },
  beforeDestroy()
  {
    this.clearBlob();
  },
  methods:
    {
      ...mapMutations('auth', ['SET_USER_DATA']),
      ruleRequired,
      validURL,
      clearBlob()
      {
        if (/^blob:/.test(this.photo || '')) URL.revokeObjectURL(this.photo);
        this.photo = null;
        this.photoBlob = null;
      },
      showUpload()
      {
        this.newLocalUpload = null;
        this.newURL = '';
        this.dlgUpload = true;
        this.$nextTick(() =>
        {
          this.$refs.frm.resetValidation();
        });
      },
      validUpload(file)
      {
        if (file)
        {
          if (file.size > 3e6) return this.$t('file_too_large');
          if (!/\.(jpg|jpeg|png)$/i.test(file.name)) return this.$t('invalid_file_type');
        }

        return true;
      },
      showCrop()
      {
        if (this.$refs.frm.validate())
        {
          if (this.typeUpload === 0)
          {
            const reader = new FileReader();
            reader.onload = e =>
            {
              this.imageData = e.target.result;
              this.dlgCrop = true;
            };
            reader.readAsDataURL(this.newLocalUpload);
          }
          else
          {
            // fetch remote image - we can't use regular AJAX or Fetch because of CORS
            const img = new Image();
            img.crossOrigin = 'anonymous'; // Important - otherwise Canvas does not allow "toDataURL"
            img.onerror = () =>
            {
              events.$emit(SNACKBAR_FAILURE, this.$t('cant_fetch'));
            };
            img.onload = () =>
            {
              const cvs = document.createElement('canvas');
              cvs.width = img.naturalWidth;
              cvs.height = img.naturalHeight;
              cvs.getContext('2d').drawImage(img, 0, 0);

              // get DataURI with Base64-encoded image data
              this.imageData = cvs.toDataURL('image/png');
              this.dlgCrop = true;

              /*
              const fileExtension = dataURI.split(',')[0].split(':')[1].split(';')[0].split('/')[1]

              // convert to BLOB
              fetch(dataURI).then(result => result.blob()).then(binary => {
                upload.append('file', binary, `test.${fileExtension}`)
                this.uploadImage(upload)
              })
              */
            };
            img.src = `https://api.codetabs.com/v1/proxy?quest=${this.newURL}`; // We have to use cors-anywhere.herokuapp.com to avoid CORS errors !!!
          }
        }
      },
      changeAvatar(blob)
      {
        /*
        if (this.$refs.frm.validate()) {
          if (this.typeUpload === 0) {
            const reader = new FileReader()
            reader.onload = e => {
              this.setImage(new Blob([e.target.result], { type: this.newLocalUpload.type }), this.newLocalUpload.name)
            }
            reader.readAsArrayBuffer(this.newLocalUpload)
          } else {
            // fetch remote image - we can't use regular AJAX or Fetch because of CORS
            const img = new Image()
            img.crossOrigin = 'anonymous' // Important - otherwise Canvas does not allow "toDataURL"
            img.onerror = () => {
              this.axiosToken = null
              this.uploading = false
              events.$emit(SNACKBAR_FAILURE, this.$t('cant_fetch'))
            }
            img.onload = () => {
              const cvs = document.createElement('canvas')
              cvs.width = img.naturalWidth
              cvs.height = img.naturalHeight
              cvs.getContext('2d').drawImage(img, 0, 0)

              // get DataURI with Base64-encoded image data
              const dataURI = cvs.toDataURL('image/png')
              const fileExtension = dataURI.split(',')[0].split(':')[1].split(';')[0].split('/')[1]

              // convert to BLOB
              fetch(dataURI).then(result => result.blob()).then(binary => {
                this.setImage(binary, `test.${fileExtension}`)
              })
            }
            img.src = `https://api.codetabs.com/v1/proxy?quest=${this.newURL}` // We have to use cors-anywhere.herokuapp.com to avoid CORS errors !!!
          }
        }
        */
        this.setImage(blob, 'test.png');
        this.dlgCrop = false;
      },
      setImage(blob, filename)
      {
        this.dlgUpload = false;
        this.photoName = filename;
        this.clearBlob();
        this.photo = URL.createObjectURL(blob);
        this.photoBlob = blob;
      },

      /**
       * @public
       * @returns {Promise<AxiosResponse<any>>}
       */
      saveChanges()
      {
        // called by the parent for each tab
        const upload = new FormData();
        upload.append('mediaType', 4);
        upload.append('file', this.photoBlob);

        return (this.photoName
          ? this.$http.post(`/${MEDIAS_API_URL}`, upload).then(response => this.$http.put(this.account['@id'], {
            mediaAvatar: response.data['@id'],
          }))
          : this.$http.put(this.account.id, {
            email: this.account.username,
          })).then(updatedUser =>
        {
          this.SET_USER_DATA({
            ...this.GET_USER_DATA,
            mediaAvatar: updatedUser.data.mediaAvatar.readPublicLink,
          });
        });
      },
    },
};
</script>

<i18n>
{
  "en": {
    "reset": "Reset",
    "submit": "Submit",
    "upload_photo": "Upload new photo",
    "allowed_files": "Allowed JPG or PNG. Max size of 3Mb",
    "new_upload": "Upload new image",
    "upload_local": "Upload local file from PC",
    "specify_url": "Specify the URL of the image",
    "choose_file": "Choose a file",
    "url_image": "Enter image URL",
    "file_too_large": "Max file size is 3Mb",
    "invalid_file_type": "Only PNG or JPG/JPEG allowed",
    "cant_fetch": "Could not fetch the image",
    "email": "E-mail"
  }
}
</i18n>
