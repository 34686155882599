const routes = [
  {
    path: '/creators',
    name: 'talent-list',
    component: () => import('@/views/talent/list/TalentList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/creators/view/:id',
    name: 'talent-details',
    redirect:
      {
        name: 'talent-details-overview',
      },
    component: () => import('@/views/talent/details/TalentDetails.vue'),
    meta: {
      layout: 'content',
    },
    children:
    [
      {
        path: 'overview',
        name: 'talent-details-overview',
        component: () => import('@/views/talent/details/left/overview/TabOverview.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'calendar',
        name: 'talent-details-calendar',
        component: () => import('@/views/talent/details/left/calendar/TabCalendar.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'deals',
        name: 'talent-details-deals',
        component: () => import('@/views/talent/details/left/deals/TabDeals.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'company',
        name: 'talent-details-company',
        component: () => import('@/views/talent/details/left/company/TabCompany.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'social-profiles',
        name: 'talent-details-social',
        component: () => import('@/views/talent/details/left/social/TabSocial.vue'),
        meta: {
          layout: 'content',
        },
      },
      {
        path: 'misc',
        name: 'talent-details-misc',
        component: () => import('@/views/talent/details/left/misc/TabMisc.vue'),
        meta: {
          layout: 'content',
        },
      },
    ],
  },
];

export default routes;
