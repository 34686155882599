const routes = [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/auth/LoginPage.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/magic-link-request',
    name: 'magic-link-request',
    component: () => import('@/views/auth/MagicLinkRequest.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/magic-link-verify',
    name: 'magic-link-verify',
    component: () => import('@/views/auth/MagicLinkVerify.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },
];

export default routes;
