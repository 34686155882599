<template>
  <div class="vertical-nav-header d-flex align-center justify-space-between ps-6 pe-5 pt-5 pb-2">
    <router-link
      to="/"
      class="d-flex align-center text-decoration-none"
      :style="menuIsVerticalNavMini ? 'margin-left: -36px; max-width: 64px;' : ''"
    >
      <v-img
        :src="logoFailed ? fallbackLogo : appLogo"
        height="50px"
        max-width="100px"
        alt="logo"
        contain
        eager
        class="app-logo me-3"
        @error="logoFailed = true"
      />
      <v-slide-x-transition>
        <h2 v-show="!(menuIsVerticalNavMini && !isMouseHovered)" class="app-title text--primary">
          {{ appName }}
        </h2>
      </v-slide-x-transition>
    </router-link>

    <v-slide-x-transition>
      <div
        v-show="!(menuIsVerticalNavMini && !isMouseHovered)"
        v-if="$vuetify.breakpoint.lgAndUp && !alwaysMini"
        class="d-flex align-center ms-1"
        @click.stop="menuIsVerticalNavMini = !menuIsVerticalNavMini"
      >
        <v-icon
          v-show="!menuIsVerticalNavMini"
          size="20"
          class="cursor-pointer"
        >
          {{ icons.mdiChevronDoubleLeft }}
        </v-icon>
        <v-icon
          v-show="menuIsVerticalNavMini && isMouseHovered"
          size="20"
          class="cursor-pointer"
        >
          {{ icons.mdiChevronDoubleRight }}
        </v-icon>
      </div>
      <v-icon
        v-else-if="!menuIsVerticalNavMini"
        size="20"
        class="d-inline-block"
        @click.stop="$emit('close-nav-menu')"
      >
        {{ icons.mdiClose }}
      </v-icon>
    </v-slide-x-transition>
  </div>
</template>

<script>
import { mdiChevronDoubleRight, mdiChevronDoubleLeft, mdiClose } from '@mdi/js';
import { inject } from '@vue/composition-api';
import useAppConfig from '@core/@app-config/useAppConfig';
import themeConfig from '@themeConfig';

export default
{
  name: 'VerticalNavMenuHeader',
  setup()
  {
    const { menuIsVerticalNavMini } = useAppConfig();
    const isMouseHovered = inject('isMouseHovered');

    return {
      menuIsVerticalNavMini,
      isMouseHovered,
      appName: themeConfig.app.name,
      alwaysMini: themeConfig.menu.alwaysMini,

      // Icons
      icons: {
        mdiClose,
        mdiChevronDoubleRight,
        mdiChevronDoubleLeft,
      },
    };
  },
  data()
  {
    return {
      logoFailed: false,
    };
  },
  computed:
  {
    appLogo()
    {
      const hostnameParts = window.location.hostname.split('.');
      const platform = hostnameParts.length > 3 ? hostnameParts[hostnameParts.length - 4] : 'loopin';

      return `${process.env.BASE_URL}platform/${platform}${this.menuIsVerticalNavMini ? '_logo_circle' : this.$vuetify.theme.isDark ? '_dark' : '_light'}.svg`; // eslint-disable-line no-nested-ternary
    },
    fallbackLogo()
    {
      return `${process.env.BASE_URL}platform/loopin_${this.menuIsVerticalNavMini ? 'logo' : this.$vuetify.theme.isDark ? 'dark' : 'light'}.svg`; // eslint-disable-line no-nested-ternary
    },
  },
};
</script>

<style lang="scss" scoped>
.app-title
{
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo
{
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant &
  {
    transform: translateX(-4px);
  }
}
</style>
