var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "auto" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-tabs",
            {
              attrs: { "show-arrows": "" },
              model: {
                value: _vm.currentTab,
                callback: function ($$v) {
                  _vm.currentTab = $$v
                },
                expression: "currentTab",
              },
            },
            _vm._l(_vm.tabs, function (tab) {
              return _c(
                "v-tab",
                { key: tab.icon },
                [
                  _c("v-icon", { staticClass: "me-3", attrs: { size: "20" } }, [
                    _vm._v(_vm._s(tab.icon)),
                  ]),
                  _c("span", [_vm._v(_vm._s(tab.title))]),
                ],
                1
              )
            }),
            1
          ),
          _c(
            "v-tabs-items",
            {
              ref: "tabs",
              model: {
                value: _vm.currentTab,
                callback: function ($$v) {
                  _vm.currentTab = $$v
                },
                expression: "currentTab",
              },
            },
            [
              _c(
                "v-tab-item",
                { attrs: { eager: "" } },
                [
                  _c("AccountSettingsAccount", {
                    attrs: { account: _vm.accountData },
                    on: { reset: _vm.resetAccount },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pa-4" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "me-3 px-4",
                  attrs: { color: "primary" },
                  on: { click: _vm.submitForm },
                },
                [_vm._v(_vm._s(_vm.$t("save_changes")))]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "me-3",
                  attrs: { color: "secondary", outlined: "", type: "reset" },
                  on: {
                    click: function ($event) {
                      _vm.show = false
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("Cancel")))]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("Spinner", {
        model: {
          value: _vm.saving,
          callback: function ($$v) {
            _vm.saving = $$v
          },
          expression: "saving",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }