<template>
  <LayoutContentVerticalNavigation :nav-menu-items="navMenuItems">
    <slot />

    <!-- Slot: Navbar -->
    <template #navbar="{ isVerticalNavMenuActive, toggleVerticalNavMenuActive }">
      <div class="navbar-content-container" :class="{'expanded-search': shallShowFullSearch}">
        <!-- Left Content: Search -->
        <div class="d-flex align-center flex-grow-1" style="z-index: 2;">
          <v-icon v-if="$vuetify.breakpoint.mdAndDown" class="me-3" @click="toggleVerticalNavMenuActive">{{ icons.mdiMenu }}</v-icon>
          <AppBarSearch
            :shall-show-full-search.sync="shallShowFullSearch"
            :data="appBarSearchData"
            :search-query.sync="appBarSearchQuery"
            @update:shall-show-full-search="handleShallShowFullSearchUpdate(isVerticalNavMenuActive, toggleVerticalNavMenuActive)"
          />
        </div>

        <!-- Right Content: I18n, Light/Dark, Notification & User Dropdown -->
        <div class="d-flex align-center right-row">
          <v-tooltip bottom>
            <template #activator="{on}">
              <v-chip v-if="isSandbox" active class="pr-4" color="#FFB400" v-on="on">
                <v-icon>{{ icons.mdiTestTube }}</v-icon>
                {{ $t('sandbox') }}
              </v-chip>
            </template>
            {{ $t('sandbox_tooltip') }}
          </v-tooltip>
          <AppBarThemeSwitcher class="mx-4" />
          <AppBarUserMenu />
        </div>
        <UserSettings v-model="dlgSettings" />
      </div>
    </template>

    <!-- Slot: Footer -->
    <template #footer>
      <div class="d-flex justify-center">
        <span class="text-caption" style="opacity: 0.4;">{{ $root.appVersion }}</span>
      </div>
    </template>
  </LayoutContentVerticalNavigation>
</template>

<script>
import { mdiMenu, mdiHeartOutline, mdiAccountPlusOutline, mdiTestTube } from '@mdi/js';
import { ref, computed, onMounted, onBeforeUnmount } from '@vue/composition-api';
import AppBarThemeSwitcher from '@core/layouts/components/app-bar/AppBarThemeSwitcher.vue';
import { getVuetify } from '@core/utils';
import LayoutContentVerticalNavigation from '@/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue';
import navMenuItemsList from '@/navigation/vertical';
import navMenuTalentList from '@/navigation/vertical/talent';
import UserSettings from '@/components/user-settings/UserSettings.vue';

// App Bar Components
import AppBarSearch from '@core/layouts/components/app-bar/AppBarSearch.vue';
import AppBarUserMenu from '@/layouts/components/app-bar/AppBarUserMenu.vue';

// Search Data
import events, { SHOW_USER_SETTINGS } from '@/events';
import store from '@/store';

export default
{
  name: 'LayoutContentVerticalNav',
  components:
  {
    LayoutContentVerticalNavigation,
    UserSettings,

    // App Bar Components
    AppBarSearch,
    AppBarThemeSwitcher,
    AppBarUserMenu,
  },
  setup()
  {
    const $vuetify = getVuetify();

    // Search
    const appBarSearchData = ref([]);
    const appBarSearchQuery = ref('');
    const shallShowFullSearch = ref(false);

    // ? Handles case where in <lg vertical nav menu is open and search is triggered using hotkey then searchbox is hidden behind vertical nav menu overlay
    const handleShallShowFullSearchUpdate = (isVerticalNavMenuActive, toggleVerticalNavMenuActive) =>
    {
      if ($vuetify.breakpoint.mdAndDown && isVerticalNavMenuActive)
      {
        toggleVerticalNavMenuActive(false);
      }
    };

    const dlgSettings = ref(false);
    const showSettings = () =>
    {
      dlgSettings.value = true;
    };
    const navMenuItems = computed(() => (store.getters['auth/IS_TALENT'] ? navMenuTalentList : navMenuItemsList));

    onMounted(() =>
    {
      events.$on(SHOW_USER_SETTINGS, showSettings);
    });

    onBeforeUnmount(() =>
    {
      events.$off(SHOW_USER_SETTINGS, showSettings);
    });

    return {
      dlgSettings,
      navMenuItems,
      handleShallShowFullSearchUpdate,

      // Search
      appBarSearchData,
      appBarSearchQuery,
      shallShowFullSearch,

      icons: {
        mdiMenu,
        mdiHeartOutline,
        mdiAccountPlusOutline,
        mdiTestTube,
      },
    };
  },
  computed:
    {
      isSandbox()
      {
        return /(^|\.)(sandbox-dashboard\.loopin\.ai|localhost)$/.test(window.location.hostname);
      },
    },
};
</script>

<style lang="scss" scoped>
.navbar-content-container
{
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  position: relative;
  padding-top: 1.5rem;
}

// ? Handle bg of autocomplete for blurred appBar
.v-app-bar.bg-blur
{
  .expanded-search
  {
    >>> .app-bar-autocomplete-box div[role='combobox']
    {
      background-color: transparent;
    }

    > .d-flex > button.v-icon
    {
      display: none;
    }

    // ===

    & > .right-row
    {
      visibility: hidden;
      opacity: 0;
    }

    >>> .app-bar-search-toggler
    {
      visibility: hidden;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "sandbox_tooltip": "You are in a Sandbox environment, the data displayed is fake"
  }
}
</i18n>
