const routes = [
  {
    path: '/agencies',
    name: 'agency-list',
    component: () => import('@/views/agency/list/AgencyList.vue'),
    meta: {
      layout: 'content',
    },
  },
];

export default routes;
