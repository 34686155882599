const routes = [
  {
    path: '/quotations',
    name: 'quotation-list',
    component: () => import('@/views/invoice/list/InvoiceList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/quotations/create/:dealid?',
    name: 'quotation-create',
    props: true,
    component: () => import('@/views/invoice/create/InvoiceCreateEdit.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/quotations/edit/:id',
    name: 'quotation-edit',
    props: true,
    component: () => import('@/views/invoice/create/InvoiceCreateEdit.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/quotations/view/:id',
    name: 'quotation-view',
    props: true,
    component: () => import('@/views/invoice/view/InvoiceView.vue'),
    meta: {
      layout: 'content',
    },
  },
];

export default routes;
