var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c(
        "v-card-text",
        { staticClass: "d-flex" },
        [
          _c(
            "v-avatar",
            {
              staticClass: "me-6 cursor-pointer",
              attrs: {
                rounded: "",
                size: "120",
                color: _vm.photo ? "transparent" : "primary",
              },
              on: { click: _vm.showUpload },
            },
            [
              _vm.photo
                ? _c("v-img", { attrs: { src: _vm.photo } })
                : _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v(_vm._s(_vm.icons.mdiAccount)),
                  ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-5" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "me-3",
                  attrs: { color: "primary" },
                  on: { click: _vm.showUpload },
                },
                [
                  _c("v-icon", { staticClass: "d-sm-none" }, [
                    _vm._v(_vm._s(_vm.icons.mdiCloudUploadOutline)),
                  ]),
                  _vm._v(" " + _vm._s(_vm.$t("upload_photo")) + " "),
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  attrs: { color: "error", outlined: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("reset")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("reset")))]
              ),
              _c("p", { staticClass: "text-sm mt-5" }, [
                _vm._v(_vm._s(_vm.$t("allowed_files"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-text",
        { staticClass: "pt-4" },
        [
          _c("v-text-field", {
            attrs: {
              value: _vm.account.username,
              label: _vm.$t("email"),
              outlined: "",
              readonly: "",
            },
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { persistent: "", width: "450" },
          model: {
            value: _vm.dlgUpload,
            callback: function ($$v) {
              _vm.dlgUpload = $$v
            },
            expression: "dlgUpload",
          },
        },
        [
          _c(
            "v-form",
            {
              ref: "frm",
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.showCrop.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "v-card",
                [
                  _c("v-card-title", [_vm._v(_vm._s(_vm.$t("new_upload")))]),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-radio-group",
                        {
                          staticClass: "mt-0 mb-7",
                          attrs: { "hide-details": "" },
                          model: {
                            value: _vm.typeUpload,
                            callback: function ($$v) {
                              _vm.typeUpload = $$v
                            },
                            expression: "typeUpload",
                          },
                        },
                        [
                          _c("v-radio", {
                            attrs: { value: 0, label: _vm.$t("upload_local") },
                          }),
                          _c("v-radio", {
                            attrs: { value: 1, label: _vm.$t("specify_url") },
                          }),
                        ],
                        1
                      ),
                      _vm.typeUpload === 0
                        ? _c("v-file-input", {
                            attrs: {
                              label: _vm.$t("choose_file"),
                              outlined: "",
                              "prepend-icon": "",
                              "prepend-inner-icon": _vm.icons.mdiPaperclip,
                              accept: ".png,.jpg,.jpeg",
                              loading: _vm.uploading,
                              rules: [_vm.ruleRequired, _vm.validUpload],
                            },
                            model: {
                              value: _vm.newLocalUpload,
                              callback: function ($$v) {
                                _vm.newLocalUpload = $$v
                              },
                              expression: "newLocalUpload",
                            },
                          })
                        : _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("url_image"),
                              placeholder: _vm.imagePlaceholder,
                              outlined: "",
                              rules: [_vm.ruleRequired, _vm.validURL],
                            },
                            model: {
                              value: _vm.newURL,
                              callback: function ($$v) {
                                _vm.newURL =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "newURL",
                            },
                          }),
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    { staticClass: "justify-center" },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mr-2",
                          attrs: { color: "primary", type: "submit" },
                        },
                        [_vm._v(_vm._s(_vm.$t("submit")))]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              _vm.dlgUpload = false
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("Cancel")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("DialogMediaCrop", {
        attrs: { media: _vm.imageData },
        on: { save: _vm.changeAvatar },
        model: {
          value: _vm.dlgCrop,
          callback: function ($$v) {
            _vm.dlgCrop = $$v
          },
          expression: "dlgCrop",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }