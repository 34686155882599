import Vue from 'vue';
import { formatZoneOffset } from './dates';

Vue.filter('percent', value => `${Math.round(10 * value) / 10}%`);

Vue.filter('traffic', value =>
{
  if (typeof value !== 'number') return '';
  const factorMegabytes = 1024;
  if (value === 0) return '0';
  if (value < factorMegabytes) return `${value} bytes`;
  value /= factorMegabytes; // eslint-disable-line no-param-reassign
  if (value < factorMegabytes) return `${value.toFixed(2)} KB`;
  value /= factorMegabytes; // eslint-disable-line no-param-reassign
  if (value < factorMegabytes) return `${value.toFixed(2)} MB`;
  value /= factorMegabytes; // eslint-disable-line no-param-reassign
  if (value < factorMegabytes) return `${value.toFixed(2)} GB`;

  return `${(value / factorMegabytes).toFixed(2)} TB`;
});

// eslint-disable-next-line import/prefer-default-export
export function dateAuto(s)
{
  return (typeof s === 'string' || typeof s === 'number') ? new Date(s.indexOf('T') > 0 ? s : `${s}T00:00:00${formatZoneOffset(new Date().getTimezoneOffset())}`) : s;
}

export function monthLocale(d, langCode)
{
  if (!d) return '';

  return dateAuto(d).toLocaleString(langCode || undefined,
    {
      year: 'numeric',
      month: 'short',
    });
}

export function dateLocale(d, langCode)
{
  if (!d) return '';

  return dateAuto(d).toLocaleString(langCode || undefined,
    {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    });
}

export function stampLocale(d, langCode)
{
  if (!d) return '';

  return dateAuto(d).toLocaleString(langCode || undefined,
    {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
}

Vue.filter('monthLocale', monthLocale);
Vue.filter('dateLocale', dateLocale);
Vue.filter('stampLocale', stampLocale);
