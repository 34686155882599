<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
    <template #activator="{ on, attrs }">
      <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
        <v-avatar size="40px" v-bind="attrs" color="primary" class="v-avatar-light-bg primary--text" v-on="on">
          <v-img v-if="getAvatarUrl()" :src="getAvatarUrl()" />
          <v-icon v-else color="primary" size="28">{{ icons.mdiAccountOutline }}</v-icon>
        </v-avatar>
      </v-badge>
    </template>
    <!-- Popup menu -->
    <v-list>
      <v-list-item>
        <v-badge bottom color="success" overlap offset-x="28" offset-y="20" dot>
          <v-list-item-avatar size="40px" color="primary" class="v-avatar-light-bg primary--text ml-0">
            <v-img v-if="getAvatarUrl()" :src="getAvatarUrl()" />
            <v-icon v-else color="primary" size="28">{{ icons.mdiAccountOutline }}</v-icon>
          </v-list-item-avatar>
        </v-badge>
        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">
            {{ `${GET_USER_DATA.firstName} ${GET_USER_DATA.lastName}`.trim() || GET_USER_DATA.username }}
          </v-list-item-title>
          <v-list-item-subtitle class="text-capitalize">
            {{ GET_USER_DATA.roleSimplified }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-2" />

      <!-- Settings -->
      <v-list-item @click="showUserSettings">
        <v-list-item-icon class="me-2">
          <v-icon size="22">{{ icons.mdiCogOutline }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('settings') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="my-2" />

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">{{ icons.mdiLogoutVariant }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiAccountOutline,
  mdiEmailOutline,
  mdiCheckboxMarkedOutline,
  mdiChatOutline,
  mdiCogOutline,
  mdiCurrencyUsd,
  mdiHelpCircleOutline,
  mdiLogoutVariant,
} from '@mdi/js';
import { mapGetters, mapMutations } from 'vuex';
import { initialAbility } from '@/plugins/acl/config';
import events, { SHOW_USER_SETTINGS } from '@/events';

export default
{
  name: 'AppBarUserMenu',
  computed:
    {
      ...mapGetters('auth', ['GET_USER_DATA']),
      icons()
      {
        return {
          mdiAccountOutline,
          mdiEmailOutline,
          mdiCheckboxMarkedOutline,
          mdiChatOutline,
          mdiCogOutline,
          mdiCurrencyUsd,
          mdiHelpCircleOutline,
          mdiLogoutVariant,
        };
      },
    },
  methods:
    {
      ...mapMutations('auth', ['SET_USER_DATA']),
      showUserSettings()
      {
        events.$emit(SHOW_USER_SETTINGS);
      },
      logoutUser()
      {
        // Remove userData from localStorage
        // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');

        // Remove userData & Ability from localStorage
        localStorage.removeItem('userData');
        localStorage.removeItem('userAbility');
        this.SET_USER_DATA({});

        // Reset ability
        this.$ability.update(initialAbility);

        // Redirect to login page
        this.$router.push({ name: 'auth-login' });
      },
      getAvatarUrl()
      {
        const avatar = this.GET_USER_DATA.mediaAvatar;
        if (avatar)
        {
          const url = new URL(avatar);
          url.searchParams.set('p', 'small');
          return url.toString();
        }
        return null;
      },
    },
};
</script>

<style lang="scss">
.user-profile-menu-content
{
  .v-list-item
  {
    min-height: 2.5rem !important;
  }
}
</style>

<i18n>
{
  "en": {
    "settings": "Settings",
    "logout": "Logout"
  }
}
</i18n>
