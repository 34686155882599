import Vue from 'vue';

// event bus
export default new Vue();

// events
export const LOGIN_REQUIRED = 'LOGIN_REQUIRED';
export const SNACKBAR_FAILURE = 'SNACKBAR_FAILURE';
export const SNACKBAR_SUCCESS = 'SNACKBAR_SUCCESS';
export const SHOW_USER_SETTINGS = 'SHOW_USER_SETTINGS';
export const PAGE_LEAVE = 'PAGE_LEAVE';
