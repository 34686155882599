var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "LayoutContentVerticalNavigation",
    {
      attrs: { "nav-menu-items": _vm.navMenuItems },
      scopedSlots: _vm._u([
        {
          key: "navbar",
          fn: function (ref) {
            var isVerticalNavMenuActive = ref.isVerticalNavMenuActive
            var toggleVerticalNavMenuActive = ref.toggleVerticalNavMenuActive
            return [
              _c(
                "div",
                {
                  staticClass: "navbar-content-container",
                  class: { "expanded-search": _vm.shallShowFullSearch },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "d-flex align-center flex-grow-1",
                      staticStyle: { "z-index": "2" },
                    },
                    [
                      _vm.$vuetify.breakpoint.mdAndDown
                        ? _c(
                            "v-icon",
                            {
                              staticClass: "me-3",
                              on: { click: toggleVerticalNavMenuActive },
                            },
                            [_vm._v(_vm._s(_vm.icons.mdiMenu))]
                          )
                        : _vm._e(),
                      _c("AppBarSearch", {
                        attrs: {
                          "shall-show-full-search": _vm.shallShowFullSearch,
                          data: _vm.appBarSearchData,
                          "search-query": _vm.appBarSearchQuery,
                        },
                        on: {
                          "update:shallShowFullSearch": function ($event) {
                            _vm.shallShowFullSearch = $event
                          },
                          "update:shall-show-full-search": [
                            function ($event) {
                              _vm.shallShowFullSearch = $event
                            },
                            function ($event) {
                              return _vm.handleShallShowFullSearchUpdate(
                                isVerticalNavMenuActive,
                                toggleVerticalNavMenuActive
                              )
                            },
                          ],
                          "update:searchQuery": function ($event) {
                            _vm.appBarSearchQuery = $event
                          },
                          "update:search-query": function ($event) {
                            _vm.appBarSearchQuery = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex align-center right-row" },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function (ref) {
                                  var on = ref.on
                                  return [
                                    _vm.isSandbox
                                      ? _c(
                                          "v-chip",
                                          _vm._g(
                                            {
                                              staticClass: "pr-4",
                                              attrs: {
                                                active: "",
                                                color: "#FFB400",
                                              },
                                            },
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v(
                                                _vm._s(_vm.icons.mdiTestTube)
                                              ),
                                            ]),
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("sandbox")) +
                                                " "
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("sandbox_tooltip")) + " ")]
                      ),
                      _c("AppBarThemeSwitcher", { staticClass: "mx-4" }),
                      _c("AppBarUserMenu"),
                    ],
                    1
                  ),
                  _c("UserSettings", {
                    model: {
                      value: _vm.dlgSettings,
                      callback: function ($$v) {
                        _vm.dlgSettings = $$v
                      },
                      expression: "dlgSettings",
                    },
                  }),
                ],
                1
              ),
            ]
          },
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("div", { staticClass: "d-flex justify-center" }, [
                _c(
                  "span",
                  {
                    staticClass: "text-caption",
                    staticStyle: { opacity: "0.4" },
                  },
                  [_vm._v(_vm._s(_vm.$root.appVersion))]
                ),
              ]),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }