import { mdiHome } from '@mdi/js';

export default [
  {
    title: 'Home',
    icon: mdiHome,
    to: 'talent-social-list',
    roles: ['ROLE_TALENT'],
  },
];
