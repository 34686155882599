const routes = [
  {
    path: '/contracts',
    name: 'contract-list',
    component: () => import('@/views/contract/list/ContractList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/contracts/view/:id',
    name: 'contract-view',
    props: true,
    component: () => import('@/views/contract/view/ContractView.vue'),
    meta: {
      layout: 'content',
    },
  },
];

export default routes;
