import moment from 'moment';

export function formatDate(date)
{
  if (moment(date).isValid()) return moment(date).format('DD/MM/YYYY HH:mm:ss');

  return null;
}

export function formatDateDeal(date)
{
  return moment(date).isValid() ? moment(date).format('MMM/YYYY') : '';
}

export function formatDateAccounting(date)
{
  return moment(date).isValid() ? moment(date).format('DD MMM YYYY') : '';
}

export function formatDateFromNow(date)
{
  return moment(date).isValid() ? moment(date).fromNow() : '';
}

export function formatAge(date)
{
  return moment().diff(date, 'years', false);
}

export function isPastDate(date)
{
  return moment(date).isValid() ? moment().diff(date) >= 0 : '';
}

export function stringDate(text)
{
  const arr = (text || '').split('-');
  const tmp = new Date(arr[0], arr[1] - 1, arr[2], 0, 0, 0, 0);

  return Number.isNaN(tmp.getTime()) ? undefined : tmp;
}

export function formatZoneOffset(minutes)
{
  const min = Math.abs(minutes) % 60;
  const hours = (Math.abs(minutes) - min) / 60;

  return `${(minutes > 0 ? '+' : '-') + String(hours).padStart(2, '0')}:${String(min).padStart(2, '0')}`;
}

export function dateText(dValue)
{
  return dValue ? `${dValue.getFullYear()}-${String(dValue.getMonth() + 1).padStart(2, '0')}-${String(dValue.getDate()).padStart(2, '0')}` : '';
}

export function dateAuto(s)
{
  return (s && (typeof s === 'string' || typeof s === 'number')) ? new Date(s.indexOf('T') > 0 ? s : `${s}T00:00:00${formatZoneOffset(new Date().getTimezoneOffset())}`) : s;
}

export function timeHMS(val)
{
  const d = dateAuto(val);

  return d
    ? `${String(d.getHours()).padStart(2, '0')
  }:${String(d.getMinutes()).padStart(2, '0')
  }:${String(d.getSeconds()).padStart(2, '0')}`
    : '12:00:00';
}

export function formatDateMedium(date)
{
  if (moment(date).isValid()) return moment(date).format('D MMM YYYY');

  return null;
}
