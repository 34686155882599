<template>
  <transition name="version">
    <div v-if="newVersionExists" class="d-inline-flex new_version_alert">
      <v-card color="brown" elevation="4" dark>
        <v-card-title class="pa-3">
          <div class="text-subtitle-1 pr-5">
            {{ $t('new_version', {version: newVersionExists}) }}
          </div>
          <v-spacer />
          <v-btn icon small @click="$emit('input',false)">
            <v-icon>{{ mdiClose }}</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-actions class="justify-center px-3 pb-4 pt-0">
          <v-btn color="success" class="black--text px-5" @click="appRefresh">{{ $t('reload') }}</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </transition>
</template>

<script>
import { mdiClose } from '@mdi/js';
import pkg from '@/../package.json';

const checkingPeriod = 200; // in seconds

function isNewerVersion(_old, _new)
{
  // return true if SemVersion A is newer than B
  const oldVer = _old.split('.');
  const newVer = _new.split('.');
  if (+oldVer[0] < +newVer[0]) return false;
  if (+oldVer[0] > +newVer[0]) return true;
  if (+oldVer[1] < +newVer[1]) return false;
  if (+oldVer[1] > +newVer[1]) return true;
  return +oldVer[2] > +newVer[2];
}

export default
{
  name: 'NewVersion',
  data()
  {
    return {
      newVersionExists: '',
      timerVersion: null,
      lastVersionCheck: null,
      windowHiddenProp: '',
      opacity: 0,
      mdiClose,
    };
  },
  watch:
    {
      newVersionExists(newVal, oldVal)
      {
        // if the user decides to dismiss and not refresh - we must continue checking
        if (oldVal && !newVal) this.scheduleVersion();
        this.$nextTick(() =>
        {
          this.opacity = newVal ? 1 : 0;
        });
      },
    },
  created()
  {
    this.firstVersionCheck();
  },
  methods:
    {
      appRefresh()
      {
        window.location.reload();
      },
      firstVersionCheck()
      {
        this.lastVersionCheck = Date.now();
        // Set the name of the hidden property and the change event for visibility
        let visibilityChange;
        if (typeof document.hidden !== 'undefined')
        {
          // Opera 12.10 and Firefox 18 and later support
          this.windowHiddenProp = 'hidden';
          visibilityChange = 'visibilitychange';
        }
        else if (typeof document.msHidden !== 'undefined')
        {
          this.windowHiddenProp = 'msHidden';
          visibilityChange = 'msvisibilitychange';
        }
        else if (typeof document.webkitHidden !== 'undefined')
        {
          this.windowHiddenProp = 'webkitHidden';
          visibilityChange = 'webkitvisibilitychange';
        }
        document.addEventListener(visibilityChange, this.handlePageVisibility, false);
        this.scheduleVersion();
      },
      handlePageVisibility()
      {
        if (!document[this.windowHiddenProp])
        {
          // if too much time has passed in the background - immediately check for new version
          if (Date.now() - this.lastVersionCheck > checkingPeriod * 1000)
          {
            if (this.timerVersion) clearTimeout(this.timerVersion);
            this.checkVersion();
          }
        }
      },
      scheduleVersion()
      {
        // check for new versions
        if (this.timerVersion) clearTimeout(this.timerVersion);
        this.timerVersion = setTimeout(this.checkVersion, checkingPeriod * 1000); // check for new version every 3.3 minutes
      },
      checkVersion()
      {
        this.timerVersion = null;
        fetch(process.env.BASE_URL + 'index.html').then(response =>
        {
          return response.text();
        }).then(t =>
        {
          this.lastVersionCheck = Date.now();
          const newVersion = t.match(/<template id="?VERSION"?>([^<]+)<\/template>/);
          if (newVersion[1])
          {
            if (isNewerVersion(newVersion[1], pkg.version))
            {
              this.newVersionExists = newVersion[1];
            }
            else this.scheduleVersion();
          }
          else this.scheduleVersion();
        }).catch(err =>
        {
          console.error('Could not check for new version', err.message || err);
          this.scheduleVersion();
        });
      },
    },
};
</script>

<style>
  .version-enter,
  .version-leave-to
  {
    opacity: 0;
    transform: translateY(100%);
  }

  .version-enter-active,
  .version-leave-active
  {
    transition: opacity 0.4s ease-out, transform 0.35s linear;
  }

  .new_version_alert
  {
    z-index: 17;
    position: fixed;
    bottom: 32px;
    left: 16px;
  }

</style>

<i18n>
{
  "en": {
    "new_version": "There is a new version v{version}",
    "reload": "Reload"
  }
}
</i18n>
