var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.canViewVerticalNavMenuGroup(_vm.item)
    ? _c(
        "v-list-group",
        {
          ref: "refVListGroup",
          staticClass: "vertical-nav-menu-group",
          class: [{ "vertical-nav-menu-group-active": _vm.isActive }].concat(
            _vm.rootThemeClasses
          ),
          attrs: { value: _vm.isGroupExpanded },
          on: {
            click: function ($event) {
              return _vm.updateGroupOpen(!_vm.isOpen)
            },
          },
          scopedSlots: _vm._u(
            [
              {
                key: "prependIcon",
                fn: function () {
                  return [
                    _c(
                      "v-icon",
                      { class: { "alternate-icon-small": !_vm.item.icon } },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.item.icon || _vm.alternateIcon) + " "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "activator",
                fn: function () {
                  return [
                    _c("v-list-item-title", [
                      _vm._v(_vm._s(_vm.t(_vm.item.title))),
                    ]),
                    _vm.item.badge
                      ? _c(
                          "v-list-item-action",
                          { staticClass: "flex-shrink-0" },
                          [
                            _c("v-badge", {
                              attrs: {
                                color: _vm.item.badgeColor,
                                inline: "",
                                content: _vm.item.badge,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
                proxy: true,
              },
            ],
            null,
            false,
            4118705515
          ),
        },
        _vm._l(_vm.item.children, function (child) {
          return _c(_vm.resolveNavItemComponent(child), {
            key: child.subheader || child.title,
            tag: "component",
            attrs: { item: child },
          })
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }