export default
{
  namespaced: true,
  state:
  {
    userData: JSON.parse(localStorage.getItem('userData') || '{}'),
  },
  getters:
    {
      GET_USER_DATA(state)
      {
        return state.userData;
      },
      IS_TALENT(state)
      {
        return state.userData?.roleSimplified === 'talent';
      },
    },
  mutations:
  {
    SET_USER_DATA(state, value)
    {
      state.userData = value;
      if (value) localStorage.setItem('userData', JSON.stringify(value));
      else localStorage.removeItem('userData');
    },
  },
};
