import Vue from 'vue';
import Vuex from 'vuex';
import appConfigStoreModule from '@core/@app-config/appConfigStoreModule';
import app from './main';
import auth from './auth';
import invoice from './invoice';
import contract from './contract';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules:
  {
    appConfig: appConfigStoreModule,
    app,
    auth,
    invoice,
    contract,
  },
});
