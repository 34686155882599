const routes = [
  {
    path: '/managers',
    name: 'manager-list',
    component: () => import('@/views/manager/list/ManagerList.vue'),
    meta: {
      layout: 'content',
    },
  },
];

export default routes;
