const routes = [
  {
    path: '/external_accounts',
    name: 'talent-social-list',
    component: () => import('@/views/talent/social/SocialList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/external_accounts/view/:id',
    name: 'account-view',
    component: () => import('@/views/talent/social/AccountView.vue'),
    meta: {
      layout: 'content',
    },
  },
];

export default routes;
