const routes = [
  {
    path: '/deals',
    name: 'deal-list',
    component: () => import('@/views/deal/list/DealList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/deals/create',
    name: 'deal-create',
    component: () => import('@/views/deal/create/DealCreate.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/deals/view/:id',
    name: 'deal-view',
    props: true,
    component: () => import('@/views/deal/view/DealView.vue'),
    meta: {
      layout: 'content',
    },
  },
];

export default routes;
