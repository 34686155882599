<template>
  <v-overlay :value="value" z-index="998" v-bind="$attrs">
    <v-progress-circular indeterminate size="64" />
  </v-overlay>
</template>

<script>
export default
{
  name: 'SpinLoader',
  inheritAttrs: false,
  props:
    {
      value:
        {
          type: Boolean,
          default: false,
        },
    },
};
</script>
