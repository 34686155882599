// axios
import axios from 'axios';
import AxiosIsCancel from 'axios/lib/cancel/isCancel';
import Vue from 'vue';
import events, { LOGIN_REQUIRED } from '@/events';

const API_URL = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_API_BASE_URL;
const hostnameParts = window.location.hostname.split('.');
const axiosIns = axios.create({
  baseURL: API_URL,
  headers:
  {
    'Accept-Language': 'en',
    platform: hostnameParts.length > 3 ? hostnameParts[hostnameParts.length - 4] : 'loopin',
  },
});

let isRefreshing = false;
let subscribers = [];

function onRefreshed(token)
{
  subscribers.map(cb => cb(token));
  subscribers = [];
}

export class AjaxError extends Error
{
  constructor(msg)
  {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(msg);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace)
    {
      Error.captureStackTrace(this, AjaxError);
    }

    this.name = 'AjaxError';
  }
}

axiosIns.interceptors.request.use(
  config =>
  {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken');

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
  },
  error => Promise.reject(AxiosIsCancel(error) ? new AjaxError('API request was cancelled') : error)
);

axiosIns.interceptors.response.use(
  response => (AxiosIsCancel(response) ? Promise.reject(new AjaxError('API request was cancelled')) : response),
  error =>
  {
    if (AxiosIsCancel(error)) return Promise.reject(new AjaxError('API request was cancelled'));
    if (error.response?.status === 500)
    {
      return Promise.reject(new Error(`Backend error - ${error.response.data?.detail || ''}`));
    }
    if (error.response?.status === 403)
    {
      // there is no token
      events.$emit(LOGIN_REQUIRED);

      return undefined;
    }
    if (error.response?.status === 401 && localStorage.getItem('accessToken'))
    {
      // refresh token has expired
      if (error.config.url === `${API_URL}/auth`)
      {
        // wrong password
        return Promise.reject(error.response?.data?.['hydra:description'] ? new Error(error.response?.data?.['hydra:description']) : error);
      }
      if (error.config.url === '/auth/token/refresh')
      {
        // could not refresh token
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        onRefreshed();

        // we use an event bus as workaround - if we import "router.js" here then ESLint complains about dependency cycle in Login.vue
        events.$emit(LOGIN_REQUIRED);
      }
      else
      {
        if (!isRefreshing)
        {
          isRefreshing = true;

          // try to refresh the token
          let refreshToken = localStorage.getItem('refreshToken');
          axiosIns.post('/auth/token/refresh', {
            refresh_token: refreshToken,
          }).then(response =>
          {
            isRefreshing = false;
            if (response)
            {
              // update tokens
              refreshToken = response.data.refresh_token;
              const accessToken = response.data.token;
              localStorage.setItem('accessToken', accessToken);
              localStorage.setItem('refreshToken', refreshToken);

              // retry original request(s)
              onRefreshed(accessToken);
            }
            else
            {
              // could not refresh token
              localStorage.removeItem('accessToken');
              localStorage.removeItem('refreshToken');

              // we use an event bus as workaround - if we import "router.js" here then ESLint complains about dependency cycle in Login.vue
              events.$emit(LOGIN_REQUIRED);
            }
          });
        }

        return new Promise((resolve, reject) =>
        {
          subscribers.push(token =>
          {
            if (token)
            {
              resolve(axios({
                ...error.config,
                headers:
                  {
                    ...error.config.headers,
                    Authorization: `Bearer ${token}`,
                  },
              }));
            }
            else
            {
              reject(new Error('Could not refresh the token'));
            }
          });
        });
      }
    }

    return Promise.reject(error.response?.data?.['hydra:description'] ? new Error(error.response?.data?.['hydra:description']) : error);
  }
);

Vue.prototype.$http = axiosIns;

export default axiosIns;
