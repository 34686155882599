import { mdiCheckboxBlankCircleOutline } from '@mdi/js';

const themeConfig = {
  debounceTime: 500, // in milliseconds
  app: {
    name: '', // 'LoopIn',
    isDark: false,
    isRtl: false,
    contentLayoutNav: 'vertical', // vertical, horizontal
    routeTransition: '', // https://vuetifyjs.com/en/styles/transitions/#api
    // ! `semi-dark` isn't available for horizontal nav menu
    skinVariant: 'default', // default, bordered, semi-dark
    contentWidth: 'full',
  },
  menu:
  {
    alwaysMini: true,
    isMenuHidden: false,
    isVerticalNavMini: true,
    verticalMenuAccordion: false,
    groupChildIcon: mdiCheckboxBlankCircleOutline,
    horizontalNavMenuGroupOpenOnHover: true,
  },
  appBar: {
    /*
    ! In Content Layout Horizontal Nav type `hidden` value won't work
    ! In Content Layout Horizontal Nav type value of `type` will affect both Appbar and Horizontal Navigation Menu
    */
    type: 'static', // fixed, static, hidden
    isBlurred: true,
  },
  footer: {
    type: 'static', // fixed, static, hidden
  },
  pagination:
    {
      rowsPerPage: 15,
    },
  themes: {
    light: {
      primary: '#FF65D1',
      accent: '#0d6efd',
      secondary: '#F57478',
      success: '#1BEA86',
      info: '#16B1FF',
      warning: '#FF66A1',
      error: '#FF4C51',
      grey: '#CCC',
      tertiary: '#6E41C0',
      disabled: '#8A8D93',
      yellow: '#FFB400',
    },
    dark: {
      primary: '#FF65D1',
      accent: '#0d6efd',
      secondary: '#F57478',
      success: '#1BEA86',
      info: '#16B1FF',
      warning: '#FF66A1',
      error: '#FF4C51',
      grey: '#CCC',
      tertiary: '#6E41C0',
      disabled: '#8A8D93',
      yellow: '#FFB400',
    },
  },
};

export default themeConfig;
