import { render, staticRenderFns } from "./LayoutContentVerticalNav.vue?vue&type=template&id=5c0f59d9&scoped=true"
import script from "./LayoutContentVerticalNav.vue?vue&type=script&lang=js"
export * from "./LayoutContentVerticalNav.vue?vue&type=script&lang=js"
import style0 from "./LayoutContentVerticalNav.vue?vue&type=style&index=0&id=5c0f59d9&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c0f59d9",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VMain } from 'vuetify/lib/components/VMain';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
installComponents(component, {VApp,VAppBar,VFooter,VMain,VNavigationDrawer,VOverlay})


/* hot reload */
if (module.hot) {
  var api = require("/builds/loopinai/dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5c0f59d9')) {
      api.createRecord('5c0f59d9', component.options)
    } else {
      api.reload('5c0f59d9', component.options)
    }
    module.hot.accept("./LayoutContentVerticalNav.vue?vue&type=template&id=5c0f59d9&scoped=true", function () {
      api.rerender('5c0f59d9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/@core/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue"
export default component.exports