const routes = [
  {
    path: '/deliverables',
    name: 'deliverable-list',
    component: () => import('@/views/deliverable/list/DeliverableList.vue'),
    meta: {
      layout: 'content',
    },
  },
];

export default routes;
