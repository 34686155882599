import axios from '@axios';
import { INVOICES_API_URL } from '@/constants/api_routes';

const defaultState = () => ({
  // new/edit
  form: {},
  isSaving: false,

  // view
  item: {},

  // collection
  list: [],
  totalItems: null,
});

export const state = () => defaultState();

export default
{
  namespaced: true,
  state: () => defaultState(),
  getters:
  {
    getInvoices(store)
    {
      return store.list;
    },
    getTotalInvoices(store)
    {
      return store.totalItems;
    },
  },
  mutations:
  {
    SET_LIST(store, invoices)
    {
      store.list = invoices;
    },
    SET_TOTAL_ITEMS(store, total)
    {
      store.totalItems = total;
    },
  },
  actions:
  {
    fetchList({ commit }, queryParams)
    {
      return new Promise((resolve, reject) =>
      {
        axios
          .get(INVOICES_API_URL, { params: queryParams })
          .then(response =>
          {
            commit('SET_LIST', response.data['hydra:member']);
            commit('SET_TOTAL_ITEMS', response.data['hydra:totalItems']);
            resolve(response);
          })
          .catch(error => reject(error));
      });
    },
  },
};
