import { render, staticRenderFns } from "./VerticalNavMenuLink.vue?vue&type=template&id=1a4210fe"
import script from "./VerticalNavMenuLink.vue?vue&type=script&lang=js"
export * from "./VerticalNavMenuLink.vue?vue&type=script&lang=js"
import style0 from "./VerticalNavMenuLink.vue?vue&type=style&index=0&id=1a4210fe&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VBadge,VIcon,VListItem,VListItemAction,VListItemIcon,VListItemTitle})


/* hot reload */
if (module.hot) {
  var api = require("/builds/loopinai/dashboard/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1a4210fe')) {
      api.createRecord('1a4210fe', component.options)
    } else {
      api.reload('1a4210fe', component.options)
    }
    module.hot.accept("./VerticalNavMenuLink.vue?vue&type=template&id=1a4210fe", function () {
      api.rerender('1a4210fe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-link/VerticalNavMenuLink.vue"
export default component.exports