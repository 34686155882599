import Vue from 'vue';
import moment from 'moment';
import { i18n } from '@/plugins/i18n';
import '@/plugins/vue-composition-api';
import '@/styles/styles.scss';
import App from './App.vue';
import './plugins/acl';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
import './helpers/filters';
import './helpers/directives';
import pkg from '../package.json';

Vue.prototype.$moment = moment;

Vue.config.productionTip = false;

new Vue({
  name: 'AppRoot',
  computed:
    {
      /**
       * @public
       * @returns {string}
       */
      appVersion()
      {
        const buildTime = new Date(process.env.BUILD_TIME);

        return `v.${pkg.version} - Build: ${buildTime.toLocaleString('fr', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
        })}`;
      },
    },
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app');
