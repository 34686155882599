// Auth
export const AUTH_API_URL = 'auth';
export const MAGIC_LINK_API_URL = '/public/request-magic-link';

// AccountingDocument
export const INVOICES_API_URL = 'invoices';
export const INVOICE_ITEMS_API_URL = 'invoice_items';

// Contracts
export const CONTRACTS_API_URL = 'contracts';

// Deals
export const DEALS_API_URL = 'deals';

// Talents
export const TALENTS_API_URL = 'talents';
export const TALENT_CATEGORIES_API_URL = 'talent_media_kit_categories';
export const TALENT_ASSISTANTS_API_URL = 'talent_assistants';
export const TALENT_INVOICES_API_URL = 'send_invoices_summary';
export const SEND_MAGIC_LINK_API_URL = 'send_magic_link';

// Brands
export const BRANDS_API_URL = 'brands';

// Agencies
export const AGENCIES_API_URL = 'agencies';

// Companies
export const COMPANIES_API_URL = 'companies';

// Templates
export const TEMPLATES_API_URL = 'contract_templates';

// Medias
export const MEDIAS_API_URL = 'medias';
export const MEDIA_KITS_API_URL = 'talent_media_kits';

// Events
export const CALENDAR_API_URL = 'calendar_events';

// Social
export const SOCIAL_API_URL = 'social_networks';
export const SOCIAL_NETWORK_PROFILE = 'social_network_profiles';
export const SOCIAL_NETWORK_MERGE_PROFILES = 'social_network_profiles/merge';
export const SOCIAL_DISCOVERY_AUTOCOMPLETE = 'search/social_discovery/autocomplete';
export const SOCIAL_DISCOVERY_STATISTICS = 'search/social_discovery/statistics';
export const SOCIAL_EXTRACT_URL = 'search/social_discovery/extract_from_url';

// Affiliates
export const AFFILIATES_API_URL = 'affiliates';

// Users
export const USERS_API_URL = 'users';

// Deliverable types
export const DELIVER_TYPES_API_URL = 'deliverable_types';

// Company contacts
export const CONTACTS_API_URL = 'company_contacts';

// Deliverables
export const DELIVERABLES_API_URL = 'deliverables';

// Quotations
export const QUOTATIONS_API_URL = 'quotations';
export const QUOTE_ITEMS_API_URL = 'quotation_items';

// Payment providers
export const PAYMENT_PROVIDERS_API_URL = 'instant_payment_providers';

// Payment requests
export const PAYMENT_REQUESTS_API_URL = 'payment_requests';

// Managers
export const MANAGERS_API_URL = 'managers';

// Search
export const SEARCH_COMPANY_API_URL = 'search/company';
export const SEARCH_DOCUMENTS_API_URL = 'search/documents';

// External accounts
export const EXT_ACCOUNT_API_URL = 'external_accounts';
