var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-grow-1" },
    [
      _c("v-spacer"),
      _c(
        "v-btn",
        {
          staticClass: "app-bar-search-toggler px-2 blue-grey",
          staticStyle: { opacity: "0.3" },
          attrs: { outlined: "" },
          on: {
            click: function ($event) {
              _vm.shallShowFullSearchLocal = true
            },
          },
        },
        [
          _c("v-icon", { staticClass: "mr-2" }, [
            _vm._v(_vm._s(_vm.icons.mdiMagnify)),
          ]),
          _vm._v(" " + _vm._s(_vm.$t("search")) + " "),
          _c("v-icon", { staticClass: "ml-5" }, [
            _vm._v(_vm._s(_vm.icons.mdiAppleKeyboardCommand)),
          ]),
          _c("v-icon", [_vm._v(_vm._s(_vm.icons.mdiAlphaK))]),
        ],
        1
      ),
      _c("v-spacer"),
      _c("v-dialog", {
        attrs: { "hide-overlay": "", persistent: "" },
        model: {
          value: _vm.shallShowFullSearchLocal,
          callback: function ($$v) {
            _vm.shallShowFullSearchLocal = $$v
          },
          expression: "shallShowFullSearchLocal",
        },
      }),
      _c("v-expand-transition", [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.shallShowFullSearchLocal,
                expression: "shallShowFullSearchLocal",
              },
            ],
            staticClass: "app-bar-global-search",
          },
          [
            _c("v-text-field", {
              ref: "refAutocomplete",
              staticClass: "app-bar-autocomplete-box",
              attrs: {
                "background-color": _vm.$vuetify.theme.dark ? null : "white",
                solo: "",
                "hide-details": "",
                placeholder: _vm.$t("search_placeholder"),
              },
              on: {
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "esc", 27, $event.key, [
                      "Esc",
                      "Escape",
                    ])
                  ) {
                    return null
                  }
                  _vm.shallShowFullSearchLocal = false
                },
                keydown: _vm.arrowNavigation,
                blur: _vm.maybeClosing,
              },
              scopedSlots: _vm._u([
                {
                  key: "prepend-inner",
                  fn: function () {
                    return [
                      _vm.searching
                        ? _c("v-progress-circular", {
                            attrs: {
                              size: "24",
                              color: "grey",
                              indeterminate: "",
                            },
                          })
                        : _c(
                            "v-icon",
                            { staticClass: "mr-1", attrs: { size: "22" } },
                            [_vm._v(_vm._s(_vm.icons.mdiMagnify))]
                          ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "append",
                  fn: function () {
                    return [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: "",
                            color: _vm.$vuetify.theme.dark ? "white" : null,
                          },
                          on: {
                            click: function ($event) {
                              _vm.shallShowFullSearchLocal = false
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v(_vm._s(_vm.icons.mdiClose))])],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
              model: {
                value: _vm.searchQueryLocal,
                callback: function ($$v) {
                  _vm.searchQueryLocal =
                    typeof $$v === "string" ? $$v.trim() : $$v
                },
                expression: "searchQueryLocal",
              },
            }),
            _c(
              "v-slide-y-transition",
              [
                _vm.searchQueryLocal
                  ? _c(
                      "v-card",
                      { staticClass: "mt-4 rounded-lg" },
                      [
                        _c(
                          "v-card-text",
                          { ref: "results", staticClass: "pt-0" },
                          [
                            _vm.results.length === 0
                              ? _c("div", { staticClass: "pt-5" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.searching
                                          ? _vm.$t("searching")
                                          : _vm.$t("no_results")
                                      ) +
                                      " "
                                  ),
                                ])
                              : [
                                  _vm.talentResults.length > 0
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-uppercase pt-5 pb-4",
                                            staticStyle: { opacity: "0.65" },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(_vm.$t("creators")) +
                                                " "
                                            ),
                                          ]
                                        ),
                                        _vm._l(
                                          _vm.talentResults,
                                          function (artist) {
                                            return _c(
                                              "v-btn",
                                              {
                                                key: artist.id,
                                                staticClass: "mb-2 px-2",
                                                attrs: {
                                                  block: "",
                                                  color:
                                                    artist ===
                                                    _vm.results[
                                                      _vm.focusedIndex
                                                    ]
                                                      ? _vm.$vuetify.theme.dark
                                                        ? "disabled"
                                                        : "grey lighten-1"
                                                      : "",
                                                  text:
                                                    artist !==
                                                    _vm.results[
                                                      _vm.focusedIndex
                                                    ],
                                                  depressed: "",
                                                  "min-height": "50",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.gotoTalent(
                                                      artist.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "pr-3" },
                                                  [
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass:
                                                          "v-avatar-light-bg primary--text",
                                                        attrs: {
                                                          size: 48,
                                                          color: artist.preview
                                                            ? "transparent"
                                                            : "primary",
                                                        },
                                                      },
                                                      [
                                                        artist.preview
                                                          ? _c("img", {
                                                              attrs: {
                                                                src: artist.preview,
                                                              },
                                                            })
                                                          : _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  size: "28",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.icons
                                                                      .mdiAccountOutline
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "text-left" },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "invoice_name",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          artist.title
                                                        ),
                                                      },
                                                    }),
                                                    _c("div", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          artist.content
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c("v-spacer"),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm.brandResults.length > 0
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-uppercase pt-5 pb-4",
                                            staticStyle: { opacity: "0.65" },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("brands")))]
                                        ),
                                        _vm._l(
                                          _vm.brandResults,
                                          function (company) {
                                            return _c(
                                              "v-btn",
                                              {
                                                key: company.id,
                                                staticClass: "mb-2 px-2",
                                                attrs: {
                                                  block: "",
                                                  color:
                                                    company ===
                                                    _vm.results[
                                                      _vm.focusedIndex
                                                    ]
                                                      ? _vm.$vuetify.theme.dark
                                                        ? "disabled"
                                                        : "grey lighten-1"
                                                      : "",
                                                  text:
                                                    company !==
                                                    _vm.results[
                                                      _vm.focusedIndex
                                                    ],
                                                  depressed: "",
                                                  "min-height": "50",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.gotoBrand(
                                                      company.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "pr-3" },
                                                  [
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass:
                                                          "v-avatar-light-bg primary--text",
                                                        attrs: {
                                                          size: 48,
                                                          color: company.preview
                                                            ? "transparent"
                                                            : "primary",
                                                        },
                                                      },
                                                      [
                                                        company.preview
                                                          ? _c("img", {
                                                              attrs: {
                                                                src: company.preview,
                                                              },
                                                            })
                                                          : _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  size: "28",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.icons
                                                                      .mdiAccountOutline
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "text-left" },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "invoice_name",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          company.title
                                                        ),
                                                      },
                                                    }),
                                                    _c("div", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          company.content
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c("v-spacer"),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm.agencyResults.length > 0
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-uppercase pt-5 pb-4",
                                            staticStyle: { opacity: "0.65" },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("agencies")))]
                                        ),
                                        _vm._l(
                                          _vm.agencyResults,
                                          function (company) {
                                            return _c(
                                              "v-btn",
                                              {
                                                key: company.id,
                                                staticClass: "mb-2 px-2",
                                                attrs: {
                                                  block: "",
                                                  color:
                                                    company ===
                                                    _vm.results[
                                                      _vm.focusedIndex
                                                    ]
                                                      ? _vm.$vuetify.theme.dark
                                                        ? "disabled"
                                                        : "grey lighten-1"
                                                      : "",
                                                  text:
                                                    company !==
                                                    _vm.results[
                                                      _vm.focusedIndex
                                                    ],
                                                  depressed: "",
                                                  "min-height": "50",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.gotoAgency(
                                                      company.id
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "pr-3" },
                                                  [
                                                    _c(
                                                      "v-avatar",
                                                      {
                                                        staticClass:
                                                          "v-avatar-light-bg primary--text",
                                                        attrs: {
                                                          size: 48,
                                                          color: company.preview
                                                            ? "transparent"
                                                            : "primary",
                                                        },
                                                      },
                                                      [
                                                        company.preview
                                                          ? _c("img", {
                                                              attrs: {
                                                                src: company.preview,
                                                              },
                                                            })
                                                          : _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  color:
                                                                    "primary",
                                                                  size: "28",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.icons
                                                                      .mdiAccountOutline
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "text-left" },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "invoice_name",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          company.title
                                                        ),
                                                      },
                                                    }),
                                                    _c("div", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          company.content
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c("v-spacer"),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm.invoiceResults.length > 0
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-uppercase pt-5 pb-4",
                                            staticStyle: { opacity: "0.65" },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("invoices")))]
                                        ),
                                        _vm._l(
                                          _vm.invoiceResults,
                                          function (doc) {
                                            return _c(
                                              "v-btn",
                                              {
                                                key: doc.id,
                                                staticClass: "mb-2 px-2",
                                                attrs: {
                                                  block: "",
                                                  color:
                                                    doc ===
                                                    _vm.results[
                                                      _vm.focusedIndex
                                                    ]
                                                      ? _vm.$vuetify.theme.dark
                                                        ? "disabled"
                                                        : "grey lighten-1"
                                                      : "",
                                                  text:
                                                    doc !==
                                                    _vm.results[
                                                      _vm.focusedIndex
                                                    ],
                                                  depressed: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.gotoInvoice(
                                                      doc.id,
                                                      true
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "pr-3" },
                                                  [
                                                    _c("pdf-invoice", {
                                                      attrs: { width: "20" },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "text-left" },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "invoice_name",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          doc.title
                                                        ),
                                                      },
                                                    }),
                                                    _c("div", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          doc.content
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c("v-spacer"),
                                                _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("stampLocale")(
                                                          doc.createdAt,
                                                          _vm.$root.$i18n.locale
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ]
                                    : _vm._e(),
                                  _vm.quotationResults.length > 0
                                    ? [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "text-uppercase pt-5 pb-4",
                                            staticStyle: { opacity: "0.65" },
                                          },
                                          [_vm._v(_vm._s(_vm.$t("quotations")))]
                                        ),
                                        _vm._l(
                                          _vm.quotationResults,
                                          function (doc) {
                                            return _c(
                                              "v-btn",
                                              {
                                                key: doc.id,
                                                staticClass: "mb-2 px-2",
                                                attrs: {
                                                  block: "",
                                                  color:
                                                    doc ===
                                                    _vm.results[
                                                      _vm.focusedIndex
                                                    ]
                                                      ? _vm.$vuetify.theme.dark
                                                        ? "disabled"
                                                        : "grey lighten-1"
                                                      : "",
                                                  text:
                                                    doc !==
                                                    _vm.results[
                                                      _vm.focusedIndex
                                                    ],
                                                  depressed: "",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.gotoInvoice(
                                                      doc.id,
                                                      false
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "pr-3" },
                                                  [
                                                    _c("pdf-invoice", {
                                                      attrs: { width: "20" },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  { staticClass: "text-left" },
                                                  [
                                                    _c("div", {
                                                      staticClass:
                                                        "invoice_name",
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          doc.title
                                                        ),
                                                      },
                                                    }),
                                                    _c("div", {
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          doc.content
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                                _c("v-spacer"),
                                                _c("div", [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm._f("stampLocale")(
                                                          doc.createdAt,
                                                          _vm.$root.$i18n.locale
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ]
                                    : _vm._e(),
                                ],
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }