import { isURL } from 'validator';

export function ruleRequired(value)
{
  return typeof value === 'number' ? true : (Array.isArray(value) ? value.length > 0 : !!value) || this.$t('Rules.RequiredField');
}

export function nonZero(value)
{
  return value > 0 || this.$t('Rules.NonZero');
}

export function validURL(value)
{
  return value
    ? (isURL(value, {
        require_protocol: true,
        allow_underscores: true,
        allow_fragments: false,
      })
        ? true
        : this.$t('Rules.InvalidURL'))
    : true;
}

export function validEmail(value)
{
  const pattern = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return pattern.test(value) || this.$t('Rules.InvalidEmail');
}
