import _ from 'lodash';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { canNavigate } from '@/plugins/acl/routeProtection';
import authRoutes from './auth';
import contractRoutes from './contract';
import dashboardRoutes from './dashboard';
import dealRoutes from './deals';
import errorRoutes from './error';
import invoiceRoutes from './invoice';
import talentRoutes from './talents';
import brandRoutes from './brands';
import agencyRoutes from './agencies';
import deliverableRoutes from './deliverables';
import quotationRoutes from './quotation';
import paymentReqRoutes from './paymentReqs';
import managerRoutes from './managers';
import accountRoutes from './account';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: to =>
    {
      const userData = JSON.parse(localStorage.getItem('userData'));
      const userRoles = userData && _.get(userData, 'roles') ? _.get(userData, 'roles') : null;

      if (userData?.roleSimplified === 'talent') return { name: 'talent-social-list' };
      if (userData?.roleSimplified === 'accountant') return { name: 'invoice-list' };
      if (userRoles && userRoles.includes('ROLE_USER')) return { name: 'deal-list' };

      return {
        name: 'auth-login',
        query: to.query
      };
    },
  },

  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  ...authRoutes,
  ...errorRoutes,
  ...dashboardRoutes,
  ...contractRoutes,
  ...invoiceRoutes,
  ...dealRoutes,
  ...talentRoutes,
  ...brandRoutes,
  ...agencyRoutes,
  ...deliverableRoutes,
  ...quotationRoutes,
  ...paymentReqRoutes,
  ...managerRoutes,
  ...accountRoutes,
  {
    path: '/media-kits',
    name: 'mediakits',
    component: () => import('@/views/media/MediaKits.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('@/views/calendar/CalendarView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior()
  {
    return {
      x: 0,
      y: 0
    };
  },
});

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, from, next) =>
{
  const userData = localStorage.getItem('userData');

  const isLoggedIn = userData && localStorage.getItem('accessToken') && localStorage.getItem('userAbility');

  if (to.name !== 'auth-login' && !canNavigate(to))
  {
    // Redirect to login if not logged in
    if (!isLoggedIn)
    {
      next({
        name: 'auth-login',
        query: { marketplace: to.query.marketplace }
      });
    }

    // If logged in => not authorized
    next({ name: 'misc-not-authorized' });
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn)
  {
    next('/');
  }

  next();
});

export default router;
