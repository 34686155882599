const routes = [
  {
    path: '/invoices',
    name: 'invoice-list',
    component: () => import('@/views/invoice/list/InvoiceList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/invoices/create/:dealid?',
    name: 'invoice-create',
    props: route => ({
      dealid: route.params.dealid,
      invoice: true,
    }),
    component: () => import('@/views/invoice/create/InvoiceCreateEdit.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/invoices/edit/:id',
    name: 'invoice-edit',
    props: route => ({
      id: route.params.id,
      invoice: true,
    }),
    component: () => import('@/views/invoice/create/InvoiceCreateEdit.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/invoices/view/:id',
    name: 'invoice-view',
    props: route => ({
      id: route.params.id,
      invoice: true,
    }),
    component: () => import('@/views/invoice/view/InvoiceView.vue'),
    meta: {
      layout: 'content',
    },
  },
];

export default routes;
