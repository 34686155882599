const routes = [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/misc/BadRoute.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/misc/NotAuthorized.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
];

export default routes;
