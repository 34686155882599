const routes = [
  {
    path: '/payment-requests',
    name: 'payment-reqs-list',
    component: () => import('@/views/payreqs/list/PaymentRequestList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/payment-requests/view/:id',
    name: 'payment-reqs-view',
    component: () => import('@/views/payreqs/view/PaymentRequestView.vue'),
    meta: {
      layout: 'content',
    },
  },
];

export default routes;
