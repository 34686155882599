const routes = [
  {
    path: '/dashboard',
    name: 'dashboard-crm',
    component: () => import('@/views/dashboard/DashboardPage.vue'),
    meta: {
      layout: 'content',
    },
  },
];

export default routes;
