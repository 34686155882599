const routes = [
  {
    path: '/brands',
    name: 'brand-list',
    component: () => import('@/views/brand/list/BrandList.vue'),
    meta: {
      layout: 'content',
    },
  },
];

export default routes;
