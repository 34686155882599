var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    _vm.resolveLayoutVariant,
    {
      tag: "component",
      class: "skin-variant--" + _vm.appSkinVariant,
      attrs: { route: _vm.$route.name },
    },
    [
      _c(
        "transition",
        { attrs: { name: _vm.appRouteTransition, mode: "out-in", appear: "" } },
        [_c("router-view")],
        1
      ),
      _c("NewVersion", {
        model: {
          value: _vm.$root.newVersionExists,
          callback: function ($$v) {
            _vm.$set(_vm.$root, "newVersionExists", $$v)
          },
          expression: "$root.newVersionExists",
        },
      }),
      _c(
        "v-snackbar",
        {
          attrs: { color: "error", top: "", text: "" },
          model: {
            value: _vm.hasError,
            callback: function ($$v) {
              _vm.hasError = $$v
            },
            expression: "hasError",
          },
        },
        [_vm._v(" " + _vm._s(_vm.errorMsg) + " ")]
      ),
      _c(
        "v-snackbar",
        {
          attrs: { color: "success", top: "", text: "" },
          model: {
            value: _vm.hasSuccess,
            callback: function ($$v) {
              _vm.hasSuccess = $$v
            },
            expression: "hasSuccess",
          },
        },
        [_vm._v(" " + _vm._s(_vm.successMsg) + " ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }