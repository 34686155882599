var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vertical-nav-menu-container d-flex flex-column",
      on: {
        mouseenter: function ($event) {
          _vm.isMouseHovered = true
        },
        mouseleave: function ($event) {
          _vm.isMouseHovered = false
        },
      },
    },
    [
      _vm._t("v-nav-menu-header", function () {
        return [
          _c("VerticalNavMenuHeader", {
            on: {
              "close-nav-menu": function ($event) {
                return _vm.$emit("close-nav-menu")
              },
            },
          }),
        ]
      }),
      _c("div", {
        staticClass: "shadow-bottom",
        class: { "d-block": _vm.shallShadowBottom },
      }),
      _c(
        "PerfectScrollbar",
        {
          staticClass: "ps-nav-menu-items",
          attrs: { options: _vm.perfectScrollbarOptions },
          on: {
            "ps-scroll-y": function (evt) {
              _vm.shallShadowBottom = evt.srcElement.scrollTop > 0
            },
          },
        },
        [
          _c(
            "v-list",
            {
              staticClass: "vertical-nav-menu-items pb-6",
              attrs: { expand: "", shaped: "" },
            },
            _vm._l(_vm.allowedMenuItems, function (item) {
              return _c(
                "v-tooltip",
                {
                  key: item.subheader || item.title,
                  attrs: { right: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function (ref) {
                          var on = ref.on
                          return [
                            _c(
                              _vm.resolveNavItemComponent(item),
                              _vm._g(
                                { tag: "component", attrs: { item: item } },
                                on
                              )
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [_vm._v(" " + _vm._s(item.subheader || item.title) + " ")]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }