<template>
  <v-dialog v-model="show" width="auto">
    <v-card>
      <!-- tabs -->
      <v-tabs v-model="currentTab" show-arrows>
        <v-tab v-for="tab in tabs" :key="tab.icon">
          <v-icon size="20" class="me-3">{{ tab.icon }}</v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <!-- tabs item -->
      <v-tabs-items ref="tabs" v-model="currentTab">
        <v-tab-item eager>
          <AccountSettingsAccount :account="accountData" @reset="resetAccount" />
        </v-tab-item>
      </v-tabs-items>

      <v-card-actions class="pa-4">
        <v-btn color="primary" class="me-3 px-4" @click="submitForm">{{ $t('save_changes') }}</v-btn>
        <v-btn color="secondary" outlined class="me-3" type="reset" @click="show = false">{{ $t('Cancel') }}</v-btn>
      </v-card-actions>
    </v-card>
    <Spinner v-model="saving" />
  </v-dialog>
</template>

<script>
import {
  mdiAccountOutline,

//  mdiLockOpenOutline,
//  mdiInformationOutline,
//  mdiBookmarkOutline,
//  mdiBellOutline,
} from '@mdi/js';
import { mapGetters } from 'vuex';
import Spinner from '@/components/SpinLoader.vue';
import events, { SNACKBAR_FAILURE } from '@/events';
import AccountSettingsAccount from './AccountSettingsAccount.vue';

export default
{
  name: 'UserSettings',
  components:
  {
    Spinner,
    AccountSettingsAccount,
  },
  props:
    {
      value:
        {
          type: Boolean,
          default: false,
        },
    },
  data()
  {
    return {
      saving: false,
      currentTab: 0,
      accountData: {},
    };
  },
  computed:
    {
      ...mapGetters('auth', ['GET_USER_DATA']),
      show:
        {
          get()
          {
            return this.value;
          },
          set(val)
          {
            this.$emit('input', val);
          },
        },
      tabs()
      {
        return [
          {
            title: this.$t('account'),
            icon: mdiAccountOutline,
          },

          /*
          {
            title: this.$t('security'),
            icon: mdiLockOpenOutline,
          },
          {
            title: this.$t('info'),
            icon: mdiInformationOutline,
          },
          {
            title: this.$t('billing'),
            icon: mdiBookmarkOutline,
          },
          {
            title: this.$t('notifications'),
            icon: mdiBellOutline,
          },
           */
        ];
      },
    },
  watch:
    {
      value(newVal)
      {
        if (newVal) this.resetAccount();
      },
    },
  methods:
    {
      submitForm()
      {
        this.saving = true;
        Promise.all(this.$refs.tabs.items.map(item => item.$children[0].saveChanges && item.$children[0].saveChanges())).then(() =>
        {
          this.show = false;
        }).catch(err =>
        {
          events.$emit(SNACKBAR_FAILURE, err);
        }).finally(() =>
        {
          this.saving = false;
        });
      },
      resetAccount()
      {
        this.accountData = JSON.parse(JSON.stringify(this.GET_USER_DATA));
      },
    },
};
</script>

<i18n>
{
  "en": {
    "account": "Account",
    "save_changes": "Save changes"
  }
}
</i18n>
